import React, { useState, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';

import arrayMutators from 'final-form-arrays';
import { Label } from '../../components/ui/label';
import { renderField } from '../../helpers/form-validations';
import { useToast } from '../../components/ui/use-toast';
import { Button } from '../../components/ui/button';

import withLoader from '../../redux/Hoc/withLoader';
import withUser from '../../redux/Hoc/withUser';
import { loadTestKafka, getEnvironmentNames } from '../../utils/util';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger
  } from '../../components/ui/dropdown-menu';
import { Card } from "../../components/ui/card";
import { DropDownIcon } from '../../assets/icons';
// import {
// 	DropdownMenu,
// 	DropdownMenuContent,
// 	DropdownMenuItem,
// 	DropdownMenuSeparator,
// 	DropdownMenuTrigger
//   } from '../ui/dropdown-menu';

const LoadMessagingBroker = ({ user, setLoader }) => {


	const { toast } = useToast();
	const navigate = useNavigate();
  const [envNames, setEnvNames] = useState([]);
  const [formState, setFormState] = useState({
    environment: ''
  })


  const TARGET_TYPE = [
    // { value: 'noAuth', label: 'No Auth' },
    // { value: 'basicAuth', label: 'Basic Auth' },
    // { value: 'bearerToken', label: 'Bearer Token' },

    { value: 'public', label: 'Public' },
    { value: 'private', label: 'Private' },
    { value: 'token', label: 'Token' },
    
    
  ];

  const SECURITY_TEST_TARGET_TYPE=TARGET_TYPE

const [targetType, setTargetType] = useState('Select Type');
  const [isTargetTypeDropdownOpen,setIsTargetTypeDropdownOpen]=useState(false)
  const handleTargetTypeDropdown = () => {
	setIsTargetTypeDropdownOpen(!isTargetTypeDropdownOpen);
  };
	const onSubmit = async (formData) => {

		try {
			const payload = {
				user_id: user?.user_id,
				kafka_broker: formData?.kafka_broker,
				kafka_topic: formData?.kafka_topic,
				kafka_message: formData?.kafka_message,
				virtual_user: formData?.virtualUsers,
				test_name: formData?.testName,
				enterprise_id: user?.enterprise_id,
				restart: false,
				subscription_id: user?.activated_subscription_id,
				object_id: user?.object_id,
				environment: user?.environment || "dev"
			};
			setLoader(true);
			const loadTestRes = await loadTestKafka(payload);
			setLoader(false);
			handleApiResponse(loadTestRes.data);
			
		} catch (e) {
			console.log('Error', e);
			toast({
				variant: 'destructive',
				title: 'Error',
				description: 'Some Error Occurred. Please Try Again.'
			});
		}
	};

	const handleApiResponse = (response) => {
		console.log(response);
		switch (response.status) {
			case 'success':
				toast({ description: response.message, variant: 'success' });
				navigate('/load')
				break;
			case 'error':
				switch (response.code) {
					case 404:
						toast({ title:"Warning",description: "You don't have any activated or valid subscription", variant: 'destructive' });
						break;
          case 409:
						toast({ description: response.message, variant: 'destructive' });
						break;
					case 400:
						toast({ description: response.message, variant: 'destructive' });
						break;
					case 500:
						toast({ description: response.message, variant: 'destructive' });
						break;
					default:
						toast({ description: 'An unknown error occurred.', variant: 'destructive' });
				}
				break;
			default:
				toast({ description: 'Some Error Occurred. Please Try Again.', variant: 'destructive' });
		}
	};

  const handleEnvironmentChange = (newEnv) => {
    setFormState((prevState) => ({
      ...prevState,
      environment: newEnv,
    }));
  };

  useEffect(() => {
    fetchEnvNames();
  }, []);

  const fetchEnvNames = async () => {
    try {
      setLoader(true);
      const response = await getEnvironmentNames({
        user_id: user.user_id,
        enterprise_id: user.enterprise_id
      });
      setEnvNames(response.data.data.Environment_list.filter(i => i))
      setLoader(false);

    } catch (error) {
      setEnvNames([])
      setLoader(false);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to fetch environment names'
      });
    }
  };

	const required = (value) => (value ? undefined : 'Required');

	const composeValidators =
		(...validators) =>
			(value) =>
				validators.reduce((error, validator) => error || validator(value), undefined);


	return (
		<main className="grid flex-1 items-start gap-4 p-4  sm:py-0 md:gap-8 w-full ">
			<div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2 manrope-fontCss">
				<Form
					onSubmit={onSubmit}
					initialValues={formState}
					mutators={{
						...arrayMutators
					}}
					render={({ handleSubmit,form }) => (
						<>
							<form onSubmit={handleSubmit}>
								<div className="grid gap-4 w-full">
                {envNames.length > 0 && (
                  <div className="flex w-full pt-2 gap-2">
                    <div className="flex-1 gap-4 font-semibold text-lg">
                      <label htmlFor="httpMethods" className="text-base">
                        Select Environment
                      </label>
                      <Field
                        name="environment"
                        component="select"
                        onChange={(event) => handleEnvironmentChange(event.target.value)}
                        className="ml-4 bg-gray-700 border border-gray-600 text-white p-1 rounded-md flex-1"
                        required

                      >
                        <option value="">Choose Environment</option>
                        {envNames.map((envObj) => (
                          <option key={envObj} value={envObj}>
                            {envObj}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                  )}
									<div className="flex w-full pt-2 gap-2">
										<div className="flex-1 gap-4 font-semibold text-lg">
											<Label htmlFor="testName" className={`mb-4`}>
												Test Name
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="testName"
												type="text"
												name="testName"
												placeholder="Write test name like “Load Test 1”, etc. "
												validate={composeValidators(required)}>
												{renderField}
											</Field>
										</div>

										<div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
											<Label htmlFor="kafka_message" className={`mb-4`}>
												Message
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="kafka_message"
												type="text"
												name="kafka_message"
												placeholder="Enter Message Here"
												validate={composeValidators(required)}>
												{renderField}
											</Field>
										</div>
									</div>

									<div className="flex w-full pt-2 gap-2">
										<div className="flex-1 gap-4 font-semibold text-lg">
											<Label htmlFor="kafka_broker" className={`mb-4`}>
												Broker
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="kafka_broker"
												type="text"
												name="kafka_broker"
												placeholder="Enter Broker Here"
												validate={composeValidators(required)}>
												{renderField}
											</Field>
										</div>

										<div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
											<Label htmlFor="kafka_topic" className={`mb-4`}>
												Topic
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="kafka_topic"
												type="text"
												name="kafka_topic"
												placeholder="Enter Topic here"
											>
												{renderField}
											</Field>
										</div>
									</div>
									{/* <div className="flex w-full pt-2 gap-2">
										<div className="flex-1 gap-4 font-semibold text-lg">
											<Label htmlFor="port" className={`mb-4`}>
												PORT
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="port"
												type="text"
												name="port"
												placeholder="Enter PORT Here"
												validate={composeValidators(required)}>
												{renderField}
											</Field>
										</div>

										<div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
											<Label htmlFor="url" className={`mb-4`}>
												URL
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="url"
												type="text"
												name="url"
												placeholder="add url"
											>
												{renderField}
											</Field>
										</div>
									</div> */}

<div className="flex w-full flex-col pt-2 mt-2 ">
<Label htmlFor="virtualUsers" className={`mb-4`}>
												Authorization Type
											</Label>
	<div className="flex flex-row w-full pt-2 space-x-4 ">
        
        <div className="flex flex-col space-y-4 w-[25%]  ">
                          

     <div className="grid grid-col-1 ">
                <DropdownMenu className="mt-2 " onOpenChange={handleTargetTypeDropdown}>
                  <DropdownMenuTrigger className="h-9 manrope-fontCss bg-[#161A27] text-white text-sm text-start rounded-md border-[#747474] border-2">
                    <div className="px-[13px] flex items-center justify-between">
                      {targetType.charAt(0).toUpperCase() + targetType.slice(1).toLowerCase()}{' '}
                      <div
                        className={`transition-transform ${isTargetTypeDropdownOpen ? 'rotate-180' : ''}`}>
                        <DropDownIcon />
                      </div>
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-[var(--radix-dropdown-menu-trigger-width)] bg-[#161A27] mt-3">
              
                    <DropdownMenuItem
                      onClick={() => setTargetType('public')}
                      className={targetType === 'public' ? 'bg-accent ' : ''}>
                      <div className="w-full text-center text-[16px] font-semibold">{`Public`}</div>
                    </DropdownMenuItem>
                    <DropdownMenuSeparator className="" />
                    <DropdownMenuItem
                      onClick={() => setTargetType('private')}
                      className={targetType === 'private' ? 'bg-accent ' : ''}>
                      <div className="w-full text-center text-[16px] font-semibold">{`Private`}</div>
                    </DropdownMenuItem>
                    {/* <DropdownMenuSeparator className="" />
                    <DropdownMenuItem
                      onClick={() => setTargetType('token')}
                      className={targetType === 'token' ? 'bg-accent ' : ''}>
                      <div className="w-full text-center text-[16px] font-semibold">{`Token`}</div>
                    </DropdownMenuItem> */}
                    
                  
                    
                  </DropdownMenuContent>
                </DropdownMenu>
              
              </div>



                         
                         </div>

                         <div className="h-full w-[1px] bg-[#6F6F6F] inline-block"></div>
     
     <div className="flex flex-col w-[80%]">
                           <Card className="w-full rounded-md min-h-9 h-auto px-3 py-2 border border-[#747474]">
                             {targetType === SECURITY_TEST_TARGET_TYPE[0].value && (
                               <div className="flex flex-col gap-4 items-center">
                                 <p className="text-xs text-[#A1A1AA]">
                                   This request does not use any authorization. Learn more about
                                   authorization.
                                 </p>
                               </div>
                             )}
                             {targetType === SECURITY_TEST_TARGET_TYPE[1].value && (
                               <div className="flex flex-col gap-4 py-2">
                                
                                 <div className="grid grid-cols-1 gap-4">
                                   <div className="grid gap-2">
                                     <div className="relative">
									 <Field
												className="w-full bg-[#161A27] mt-2"
												id="userName"
												type="text"
												name="userName"
												placeholder="Enter username Here"
												// validate={composeValidators(required)}
												>
												{renderField}
											</Field>
                                     </div>
                                   </div>
                                 </div>
                                 <div className="grid gap-2">
                                   <div className="relative">
								   <Field
												className="w-full bg-[#161A27] mt-2"
												id="password"
												type="text"
												name="password"
												placeholder="Enter password here"
											>
												{renderField}
											</Field>
                                   </div>
                                 </div>
                               </div>
                             )}
                            
                           
                                                                                                                                </Card>
                         </div>
     
     
                       </div>
</div>



									{/* <div className="flex w-full pt-2 gap-2">
										<div className="flex-1 gap-4 font-semibold text-lg">
											<Label htmlFor="userName" className={`mb-4`}>
												User Name
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="userName"
												type="text"
												name="userName"
												placeholder="Enter username Here"
												// validate={composeValidators(required)}
												>
												{renderField}
											</Field>
										</div>

										<div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
											<Label htmlFor="password" className={`mb-4`}>
												Password
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="password"
												type="text"
												name="password"
												placeholder="Enter password here"
											>
												{renderField}
											</Field>
										</div>
									</div> */}
									<div className="flex w-full pt-2 gap-2">
										<div className="flex-1 gap-4 font-semibold text-lg">
											<Label htmlFor="virtualUsers" className={`mb-4`}>
												Virtual Users
											</Label>
											<Field
												className="w-full bg-[#161A27] mt-2"
												id="virtualUsers"
												type="text"
												name="virtualUsers"
												placeholder="Enter number of Virtual Users here "
												validate={composeValidators(required)}>
												{renderField}
											</Field>
										</div>

										{/* <div className="flex-1 gap-4 gap-y-2 font-semibold text-lg">
                      <Label htmlFor="url" className={`mb-4`}>
                      URL
                      </Label>
                      <Field
                        className="w-full bg-[#161A27] mt-2"
                        id="url"
                        type="text"
                        name="url"
                        placeholder="Add URL here"
                        validate={composeValidators(required)}>
                        {renderField}
                      </Field>
                    </div> */}



									</div>

								</div>
								<div className="flex flex-row mt-4 gap-4 w-full mb-4">
									<div className="w-1/2 justify-start items-center">
										{/* <Button
											type="submit"
											className="bg-[#222938] text-white h-10 w-60 text-base font-medium gap-1 border-[#444444] border">
											<Plus />
											New Test
										</Button> */}
									</div>
									<div className="flex w-1/2 justify-end gap-4 ">
										<Button
											type="button"
											onClick={()=>{
												setLoader(true)
												form.restart()
												setTimeout(() => {
													setLoader(false)
													navigate("/load")
												}, 1000);
											}}
											className="bg-[#222938] text-white h-10 w-60 text-base font-medium border-[#444444] border">
											Discard Changes
										</Button>
										<Button type="submit" className=" text-white h-10 w-60 text-base font-medium">
											Run Test
										</Button>
									</div>
								</div>
							</form>

						</>
					)}
				/>
			</div>
		</main>
	);
};


export default withLoader(withUser(LoadMessagingBroker));
